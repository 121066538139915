
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "underscore";
import Lender from "@/models/Liink/Lender";
import Regex from "@/utils/regex";

@Component
export default class CardRuleDrawer extends Vue {
  @Prop() lender!: Lender;
  @Prop() create!: boolean;
  @Prop() open!: boolean;
  @Prop() loading!: boolean;

  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    logo: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        pattern: Regex.url,
        message:
          "No es una url válida, debe respetar el formato http://www.url.com o similar.",
      },
    ],
    url: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        pattern: Regex.url,
        message:
          "No es una url válida, debe respetar el formato http://www.url.com o similar.",
      },
    ],
    description: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
  };

  createLender() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (valid) {
        this.$emit("create", this.lender);
      }
    });
  }

  updateLender() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (valid) {
        this.$emit("update", this.lender.id, this.lender);
      }
    });
  }

  closeDrawer() {
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("close");
  }
}
