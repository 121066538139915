
import { Component, Prop, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import Lender from "@/models/Liink/Lender";

@Component({ components: { DeletePopover } })
export default class LenderCard extends Vue {
  @Prop() lenders!: Array<Lender>;

  deleteLender(id: number) {
    this.$emit("delete", id);
  }

  updateLender(lender: Lender) {
    this.$emit("update", lender);
  }

  createLender() {
    this.$emit("create");
  }
}
